import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import FullWidthFillGraph from '../../../../shared/components/widgets/FullWidthFillGraph';
import Panel from '../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import { formatNumberOnePlace } from '../../../../shared/components/table/functions';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

import config from '../../../../config';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class RankAnalytics extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      graphLoad: false,
      checkedItem: new Set(),
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      salesRank: [],
      salesRankHold: [],
      lineList: [],
      filterOption: {},
      filterOptions: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getGraphData = (comboId) => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, graphData, lineList,
    } = this.state;
    const splitData = comboId.split('~-~', 2);
    const asin = splitData[0];
    const category = splitData[1];
    const salesRankRankByDateUrl = `${apiUrl}/customers/sales-rank/rank-by-date?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&asin=${asin}&category=${encodeURI(category).replace('&', '%26')}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      graphLoad: true,
    });

    if (!lineList.includes(asin)) {
      fetch(salesRankRankByDateUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          const graphDataSort = data.sort((a, b) => {
            const checkPos = (b.rank_date > a.rank_date) ? -1 : 0;
            return (b.rank_date < a.rank_date) ? 1 : checkPos;
          });

          let newGraphData = [];
          if (graphData.length === 0) {
            graphDataSort.forEach((date) => {
              newGraphData.push({ rank_date: date.rank_date, [`${asin}`]: date.sales_rank });
            });
          } else {
            // some data already exists so let's try to add the new data.
            newGraphData = graphData;
            graphDataSort.forEach((graphDataSortItem) => {
              const newGraphDataRow = newGraphData.find(graphDataOrig => graphDataOrig.rank_date === graphDataSortItem.rank_date);
              if (newGraphDataRow) {
                newGraphDataRow[`${asin}`] = parseInt(graphDataSortItem.sales_rank, 10);
              } else {
                newGraphData.push({ rank_date: graphDataSortItem.rank_date, [`${asin}`]: graphDataSortItem.sales_rank });
              }
            });
          }

          lineList.push(asin);

          this.setState({
            graphLoad: false,
            graphData: newGraphData,
            lineList,
          });
        }).catch(() => {
          this.setState({
            graphLoad: false,
          });
        });
    } else {
      const newLineList = [];
      lineList.forEach((item) => {
        if (item !== asin) {
          newLineList.push(item);
        }
      });
      delete lineList[asin];
      this.setState({
        graphLoad: false,
        lineList: newLineList,
      });
    }
  };

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const salesRankUrl = `${apiUrl}/customers/sales-rank/asin-avg-by-date?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      graphLoad: true,
      initalLoad: true,
    });

    fetch(salesRankUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const dataSort = data.sort((a, b) => {
          const checkPos = (parseInt(b.sales_rank_avg, 10) > parseInt(a.sales_rank_avg, 10)) ? -1 : 0;
          return (parseInt(b.sales_rank_avg, 10) < parseInt(a.sales_rank_avg, 10)) ? 1 : checkPos;
        });

        const uniqueCategories = [...new Set(dataSort.map(item => item.refined_category_name))];
        const uniqueCategoriesSorted = uniqueCategories.sort((a, b) => {
          const checkPos = (b > a) ? -1 : 0;
          return (b < a) ? 1 : checkPos;
        });
        const filterOptions = [{ label: 'ALL CATEGORIES', value: '' }, { label: 'ALL GRAPHED', value: 'ALL GRAPHED' }];
        uniqueCategoriesSorted.forEach((category) => {
          if (category) {
            filterOptions.push({ label: category, value: category });
          }
        });

        this.setState({
          graphLoad: false,
          graphData: [],
          initalLoad: false,
          salesRank: dataSort,
          salesRankHold: dataSort,
          filterOptions,
          filterOption: { label: '', value: '' },
          lineList: [],
          checkedItem: new Set(),
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  };

  handleCheckboxChange = (event) => {
    const { checkedItem } = this.state;
    const { target } = event;
    const updateSet = new Set(checkedItem);
    if (updateSet.has(target.id)) {
      updateSet.delete(target.id);
    } else {
      updateSet.add(target.id);
    }

    this.setState({
      checkedItem: updateSet,
      graphLoad: true,
    }, () => {
      this.getGraphData(target.id);
    });
  }

  handleFilterChange = (filterOption) => {
    const { salesRankHold, checkedItem } = this.state;
    let salesRank = salesRankHold;
    if (filterOption.value !== '') {
      if (filterOption.value === 'ALL GRAPHED') {
        salesRank = salesRankHold.filter(item => checkedItem.has(`${item.asin}~-~${item.refined_category_name}`));
      } else {
        salesRank = salesRankHold.filter(item => item.refined_category_name === filterOption.value);
      }
    }
    this.setState({
      filterOption,
      salesRank,
    });
  }

  render() {
    const {
      graphData, graphLoad, checkedItem, salesRank, initalLoad, lineList, filterOption, filterOptions, reportStartDate, reportEndDate,
    } = this.state;

    const { theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <FullWidthFillGraph
              data={graphData}
              dataType="asin"
              dataKey="rank_date"
              title="Rank Graph"
              initalLoad={graphLoad}
              lineList={lineList}
              tickFormatter={number => number}
              colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
            />
          </Row>
        </Col>

        <Panel
          lg={12}
          md={12}
          title="Filter By Category"
          subhead=""
          parentRefresh={initalLoad}
        >
          <div className="topbar__dynamicDropdownWidth">
            <Select
              value={filterOption}
              onChange={this.handleFilterChange}
              options={filterOptions}
              theme={selectTheme => ({
                ...selectTheme,
                colors: {
                  ...selectTheme.colors,
                  primary: `${themeColors.colorPrimary}`,
                  neutral0: `${themeColors.colorBackground}`,
                  neutral80: `${themeColors.colorText}`,
                },
              })}
            />
          </div>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title="Rank Analytics"
          subhead="ASIN with Rank Data"
          parentRefresh={initalLoad}
        >
          <Table responsive striped className="dashboard__table-orders" id="top10">
            <thead>
              <tr>
                <th>Graph</th>
                <th>Image</th>
                <th>ASIN</th>
                <th>Title</th>
                <th>Refined Category Name</th>
                <th>Sales Rank</th>
                <th>Brand</th>
              </tr>
            </thead>
            <tbody>
              {salesRank.map(product => (
                <tr>
                  <td>
                    <input
                      id={`${product.asin}~-~${product.refined_category_name}`}
                      // className="checkbox-btn__checkbox"
                      type="checkbox"
                      checked={checkedItem.has([`${product.asin}~-~${product.refined_category_name}`].toString()) ? 'checked' : ''}
                      onChange={this.handleCheckboxChange}
                      disabled={graphLoad}
                    />
                  </td>
                  <td>
                    <LazyLoad>
                      <img src={product.image_url} alt={product.asin} style={{ width: 'auto', height: 'auto' }} />
                    </LazyLoad>
                  </td>
                  <td>{product.asin}</td>
                  <td>{product.title}</td>
                  <td>{product.refined_category_name}</td>
                  <td>{formatNumberOnePlace(product.sales_rank_avg)}</td>
                  <td>{product.brand}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(RankAnalytics);
